import { db, auth, functions } from "../config/main_config";

export const billingCheck = function () {
    db.collection("users").doc(auth.currentUser.uid).get().then((userDoc) => {
        if (userDoc.exists) {
            let roles = userDoc.data()?.roles;
            let mspImpersonation = userDoc.data()?.mspImpersonation;

            if (roles?.msp !== true) {
                db.collection("customers").doc(window.companyID).get().then((customerDoc) => {
                    if (customerDoc.exists) {
                        let customerPlan = customerDoc.data()?.customerPlan;
        
                        if (customerPlan === "Starter") {
                            let billingCheck = functions.httpsCallableFromURL(import.meta.env.VITE_cloudFunctionURL + "/billingcheck");
                            billingCheck({ companyID: window.companyID }).then((response) => {
                                if (response.data.active !== true) { 
                                    window.location.href = "no_access.html?billingFailed=true";
                                }
                            }).catch((error) => {
                                console.error(error);
                            })
                        }
                    }
                })
            } else if (roles?.msp === true && mspImpersonation === true) {
                db.collection("customers").doc(window.companyID).get().then(async (customerDoc) => {
                    if (customerDoc.exists) {
                        let customerPlan = customerDoc.data()?.customerPlan;
        
                        if (customerPlan === "Starter") {
                            let billingCheck = functions.httpsCallableFromURL(import.meta.env.VITE_cloudFunctionURL + "/billingcheck");
                            const billingDoc = await db.collection("customers").doc(window.companyID).collection("companySettings").doc("billing").get();
                            if (billingDoc.exists) {
                                let billingMap = billingDoc.data()?.billingMap;
                                let mspBilling = billingMap?.mspBilling;
                                if (mspBilling === true) {
                                    await billingCheck({ companyID: window.companyID }).then((response) => {
                                        if (response.data.active !== true) { 
                                            window.location.href = "no_access.html?billingFailed=true&mspBilling=true";
                                        }
                                    }).catch((error) => {
                                        console.error(error);
                                    })
                                } else {
                                    await billingCheck({ companyID: window.companyID }).then((response) => {
                                        if (response.data.active !== true) { 
                                            window.location.href = "no_access.html?billingFailed=true";
                                        }
                                    }).catch((error) => {
                                        console.error(error);
                                    })
                                }
                            }
                        }
                    }
                })
            }
        }
    })
}